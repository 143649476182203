import React from "react";
import {Image, Box, Grid, Link, Container, IconButton, Heading, AspectRatio, AspectImage, useThemeUI} from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import { lookUpIcon } from "../IconLibrary";
import FontIcon from "../components/FontIcon";


const ShowcaseImage: React.FC<{src: string}> = ({src}) =>
  <Box sx={{position: 'relative', paddingBottom:'56.25%'}}>
    <Image src={src} sx={{position: 'absolute', width: '100%', height: '100%',objectFit: 'cover', borderRadius:8, overflow: 'hidden'}}/>
  </Box>

const ReadMoreLink: React.FC<{slug: string}> = ({slug}) =>
  <Link
    as={GatsbyLink}
    //@ts-expect-error
    to={slug}
  >
    Read More
  </Link>

interface ShowcaseNode {
  node: {
    id: string,
    frontmatter: ShowcaseFrontmatter
    fields: {
      slug: any
    }
  }
}

interface ShowcaseFrontmatter {
  title: string,
  subTitle: string,
  showcase?: {
    picture: string,
    description: string,
    links?: {url: string, icon?: string}[]
  }
}

const Showcase: React.FC<{showcases: readonly ShowcaseNode[]}> = props => {
  props.showcases.forEach(s => s.node.fields.slug);
  return (
    <Grid gap={[5,6]}>
      {props.showcases.map((project, index) => project.node.frontmatter.showcase && (
          <Grid key={project.node.id} gap={[2,4]} columns={[1,2]} sx={{direction: ['ltr', index % 2 === 0 ? 'ltr': 'rtl']}}>
            <ShowcasePicture frontmatter={project.node.frontmatter}/>
            <ShowcaseDescription frontmatter={project.node.frontmatter} slug={project.node.fields.slug}/>
          </Grid>
        ))
      }
    </Grid>
  )
}

const ShowcaseDescription: React.FC<{frontmatter: ShowcaseFrontmatter,  slug: any}> = ({frontmatter, slug}) => (
  <Box sx={{direction: 'ltr'}}>
    <Box sx={{textAlign: 'center'}}>
      <Heading as='h1'>{frontmatter.title}</Heading>
      <Heading as='h3' color='muted'>{frontmatter.subTitle}</Heading>
    </Box>
    <br />
    {frontmatter.showcase.description}
    {/*<br />
    <br />
    <ReadMoreLink slug={slug}/>*/}
    <br />
    <br />
    <br />
    {
      frontmatter.showcase.links &&
      frontmatter.showcase.links.map(link =>
        <Link variant='icon' href={link.url} target="_blank" sx={{ marginRight: '1em'}}>
          <FontIcon width={64} height={64} icon={lookUpIcon(link.icon || "globe")}/>
        </Link>
      )
    }
  </Box>
)

const ShowcasePicture: React.FC<{frontmatter: ShowcaseFrontmatter}> = ({frontmatter}) => (
  <Box>
    <ShowcaseImage src={frontmatter.showcase.picture} />
  </Box>
)

export default Showcase;
