import React from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

const FontIcon: React.FC<{width: number, height: number, icon: IconDefinition}> = ({width, height, icon}) =>
  <svg width={width} height={height} viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`} data-icon={icon.iconName}>
  {
    typeof icon.icon[4] === 'string'
      ? <path fill='currentColor' d={icon.icon[4]}/>
      : icon.icon[4].map(path => <path fill='currentColor' d={path}/>)
  }
  </svg>

export default FontIcon;