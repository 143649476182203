import { faGlobe, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faItchIo, faGithub, faGitlab, faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';

let dictionary: {[name: string]: IconDefinition} = {};

const icons = [faGlobe, faItchIo, faGithub, faGitlab, faAndroid, faApple];
icons.forEach(icon => {
    dictionary[icon.iconName] = icon;
});

export function lookUpIcon(name: string) {
    return dictionary[name];
}