import React, { ReactNode, useEffect } from "react";
import { graphql, PageProps, useStaticQuery, Link as GatsbyLink } from "gatsby";
import Showcase from "../components/Showcase";
import Img from "gatsby-image";
import "../index.css";
import {
  Button,
  Box,
  Flex,
  Text,
  Heading,
  Link,
  Divider,
  Container,
  jsx,
  Styled,
  useColorMode,
} from "theme-ui";
import { faEnvelope, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faGithub,
  faItchIo,
} from "@fortawesome/free-brands-svg-icons";
import TopMenu from "../components/TopMenu";
import FontIcon from "../components/FontIcon";

const BigContactLink: React.FC<{ href: string; icon: IconDefinition }> = ({
  href,
  icon,
}) => (
  <Link variant="icon" href={href} sx={{ marginRight: "1em" }}>
    <FontIcon width={64} height={64} icon={icon} />
  </Link>
);

const HeaderMenuLink: React.FC<{ href: string; children: ReactNode }> = ({
  href,
  children,
}) => (
  <Link
    variant="headerMenu"
    as={GatsbyLink}
    //@ts-expect-error
    to={href}
  >
    {children}
  </Link>
);

const Home: React.FC<PageProps<GatsbyTypes.IndexQuery>> = ({ data }) => {
  const projects = data.allMdx.edges;
  //color scheme workarouund https://github.com/system-ui/theme-ui/issues/787#issuecomment-602845254
  const [, setColorMode] = useColorMode();
  useEffect(() => {
    const switchMode = (e) => {
      const isDarkMode = e.matches;
      setColorMode(isDarkMode ? "dark" : "light");
    };
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    setTimeout(() => switchMode(darkModeMediaQuery), 300);
    darkModeMediaQuery.addEventListener("change", switchMode);
    return () => darkModeMediaQuery.removeEventListener("change", switchMode);
  }, [setColorMode]);
  return (
    <div>
      <div
        style={{
          height: "100vh",
          margin: 0,
        }}
      >
        <Img
          fluid={data.headerImage.childImageSharp.fluid}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            filter: "brightness(30%)",
          }}
        />
        <TopMenu />
        <Box
          id="Home"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Flex sx={{ flexDirection: "column", textAlign: "center" }}>
            <Heading as="h1" sx={{ color: "white" }}>
              Games are my passion
            </Heading>
            <Heading as="h1" sx={{ color: "white" }}>
              Programming my hobby
            </Heading>
          </Flex>
        </Box>
      </div>
      <Box
        id="AboutMe"
        sx={{ textAlign: "center", maxWidth: 512, mx: "auto", p: 3 }}
      >
        <Heading sx={{ p: 3 }}>Hi there! I'm Kevin</Heading>
        <Text sx={{ textAlign: "left" }}>
          Currently working as a Game Engine Programmer.
          I enjoy squeezing the most out of the CPU and GPU,
          as well as implementing something useable on top of it.
          <br />
          <br />I really love games and programming, and as game development
          pose interesting challenges, I can't imagine anything more fascinating
          than developing games!
        </Text>
        <br />
        <br />
        <BigContactLink
          href={"https://www.linkedin.com/in/kevinbalz/"}
          icon={faLinkedin}
        />
        <BigContactLink href="mailto:kevin.balz@web.de" icon={faEnvelope} />
        <BigContactLink href={"https://github.com/KevinBalz"} icon={faGithub} />
        <BigContactLink href={"https://wayward.itch.io/"} icon={faItchIo} />
      </Box>
      <br />
      <br />
      <Heading id="Showcase" sx={{ textAlign: "center" }}>
        Showcase
      </Heading>
      <Divider />
      <Container p={[3, 4]}>
        <Showcase showcases={projects} />
      </Container>
      <br />
      <footer>
        <Box sx={{ padding: "2em 2em", background: "black" }}>
          <Heading as="h4" sx={{ color: "white", padding: "0 0 0.75em 0" }}>
            Contact Me!
          </Heading>
          <Link variant="contactMeLink" href="mailto:kevin.balz@web.de">
            <FontIcon width={16} height={16} icon={faEnvelope} />
            &nbsp; kevin.balz@web.de
          </Link>
        </Box>
      </footer>
    </div>
  );
};

export default Home;

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        title
      }
    }
    headerImage: file(relativePath: { eq: "DSC00542.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(
      sort: { fields: [frontmatter___showcase___sort] }
      filter: { frontmatter: { showcase: { hide: { ne: true } } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subTitle
            showcase {
              sort
              picture
              description
              links {
                url
                icon
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
